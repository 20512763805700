import { render, staticRenderFns } from "./monitoring.vue?vue&type=template&id=0b4d68cd&scoped=true&"
import script from "./monitoring.vue?vue&type=script&lang=js&"
export * from "./monitoring.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b4d68cd",
  null
  
)

export default component.exports